<template>
  <v-container>
    <div class="text-center">
      <v-card
        color="box_bg"
      >
        <v-img
          src="../assets/img/send_ws_bg.webp"
          class="d-flex align-center"
        >
          <div
            class="pa-5 white--text"
          >
            <v-icon large>
              mdi-message-processing
            </v-icon>
            <h3>{{ $t('fsxxzqjj') }}</h3>
          </div>
        </v-img>
      </v-card>

      <v-card
        color="box_bg"
        class="mt-3"
      >
        <!--
        <v-card-text
          class="text-center"
        >
          <div
            v-if="todaycount<2 && countdownhour<1"
            class="my-3 text-h6"
          >
            + R$
            <strong class="orange--text">{{ task.amount }}</strong>
          </div>
          <v-card-text
            v-if="todaycount<2 && countdownhour>0"
            class="text-center py-5 primary--text"
          >
            <v-chip>
              <v-icon
                left
              >
                mdi-clock-time-eight-outline
              </v-icon>
              {{ daojishi }}
            </v-chip>
            <div class="mt-3">
              {{ $t('xctjsj2') }}
            </div>
          </v-card-text>
          <v-card-text
            v-if="todaycount>1"
            class="text-center py-5 primary--text"
          >
            <div class="mt-3">
              {{ $t('ytzntj2c3') }}
            </div>
          </v-card-text>
          <v-btn
            v-if="todaycount<2 && countdownhour<1"
            depressed
            block
            :loading="loading"
            :disabled="task.status!=1"
            color="#25d366"
            @dblclick="dblclick"
            @click="whatsappaward"
          >
            {{ $t('lingqu') }}
          </v-btn>

          <div class="mt-3 caption opacity-5">
            {{ $t('ljlq') }}: R$ {{ awardsum }}
          </div>
        </v-card-text>

        <v-divider class="opacity-3" />
        -->

        <v-card-text v-if="todaycount<2 && countdownhour<1">
          <!--
          <div class="pb-3">
            {{ $t('bwmfs') }}
            <div>
              {{ $t('mwc10t') }} R$ {{ task.amount }}
            </div>
          </div>
          -->

          <div class="pb-3">
            <v-subheader class="subtitle-2">
              1. 分享帖子
            </v-subheader>

            <v-text-field
              flat
              dense
              solo
              hide-details
              readonly
              background-color="btn_bg"
              value="https://www.LoveJogo.com/?code=6U6LH"
              append-icon="mdi-content-copy"
              @click:append="doCopy(invite_url)"
            />

            <div
              class="text-center pt-2"
            >
              <v-btn
                icon
                x-large
                color="#0766ff"
                :href="fburl"
                target="_blank"
                rel="noopener"
              >
                <v-icon>mdi-facebook</v-icon>
              </v-btn>
              <v-btn
                icon
                x-large
                color="pink"
                :href="insurl"
                target="_blank"
                rel="noopener"
              >
                <v-icon>mdi-instagram</v-icon>
              </v-btn>
              <v-btn
                icon
                x-large
                color="#25d366"
                :href="whatsappurl"
                target="_blank"
                rel="noopener"
              >
                <v-icon>mdi-whatsapp</v-icon>
              </v-btn>
              <v-btn
                icon
                x-large
                color="#1d9bf0"
                :href="whatsappurl"
                target="_blank"
                rel="noopener"
              >
                <v-icon>mdi-twitter</v-icon>
              </v-btn>
              <v-btn
                icon
                x-large
                color="#00a884"
                :href="whatsappurl"
                target="_blank"
                rel="noopener"
              >
                <v-icon>mdi-message-processing</v-icon>
              </v-btn>
            </div>
          </div>

          <v-divider class="opacity-3" />

          <div class="py-3">
            <v-subheader class="subtitle-2">
              2. 发送短信
            </v-subheader>
            <v-row dense>
              <v-col cols="4">
                <small>5516994325702</small>
              </v-col>
              <v-col cols="4">
                <small>5516994325702</small>
              </v-col>
              <v-col cols="4">
                <small>5516994325702</small>
              </v-col>
              <v-col cols="4">
                <small>5516994325702</small>
              </v-col>
              <v-col cols="4">
                <small>5516994325702</small>
              </v-col>
              <v-col cols="4">
                <small>5516994325702</small>
              </v-col>
              <v-col cols="4">
                <small>5516994325702</small>
              </v-col>
              <v-col cols="4">
                <small>5516994325702</small>
              </v-col>
            </v-row>

            <v-btn
              depressed
              block
              color="#00a884"
              class="mt-3"
              @click="whatsappaward"
            >
              {{ $t('hqyzm') }}
            </v-btn>
          </div>

          <!--
          <v-list
            rounded
            dense
            color="nav_bg"
            class="text-left"
          >
            <v-list-item
              v-for="(item, index) in records"
              :key="'r'+index"
            >
              <v-list-item-content>
                <v-list-item-title>{{ item.phone }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <div
                  v-if="item.status==1"
                  class="d-flex align-content-center green--text"
                >
                  <v-icon
                    small
                    color="green"
                    class="mr-1"
                  >
                    mdi-check-circle
                  </v-icon>
                  {{ $t('yjfs') }}
                </div>
                <v-btn
                  v-if="item.status==0 && item.current"
                  depressed
                  :loading="sending"
                  :disabled="sending"
                  color="#00a884"
                  :href="'sms:'+item.phone+'?body=euamojogo.com 🎁Plataforma dando 12,77 se cadastrar no CPFBora aproveitar que tá pagando muito 💸💗Meusss ganhos de agora nem acredito 🥺🙏🏼agora R$ 12.5 de bônus posta no Facebook💸 corra vamos testar👉 https://euamojogo.com 🤝 Amigos, por favor me respondam se você está interessado ou não.'"
                  target="_blank"
                  @dblclick="dblclick"
                  @click="whatsappsended(item.id)"
                >
                  {{ $t('hqyzm') }}
                </v-btn>
                <v-btn
                  v-if="item.status==0 && !item.current"
                  depressed
                  :disabled="true"
                  color="#00a884"
                >
                  {{ $t('hqyzm') }}
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
          -->
        </v-card-text>
      </v-card>

      <v-card
        color="box_bg"
        class="mt-3"
      >
        <v-card-text>
          <div
            v-if="f1!=null"
            class="nav_bg pa-3 rounded mx-auto"
          >
            <div class="mb-2">
              <v-btn
                depressed
                fab
                x-small
                dark
                color="red accent-2"
                @click="delimg"
              >
                <v-icon color="red1">
                  mdi-delete-forever
                </v-icon>
              </v-btn>
            </div>
                
            <img
              v-if="f1==null"
              width="100"
              src="../assets/img/pic/postimg-01.jpg"
            >
            <img
              v-if="f1!=null"
              width="100"
              :src="f1"
            >
          </div>
              
          <div
            v-if="f1==null && todaycount<6"
            class="d-flex justify-center"
          >
            <v-avatar
              color="nav_bg"
              size="56"
            >
              <v-file-input
                ref="ff1"
                v-model="file1"
                hide-input
                accept="image/png, image/jpeg, image/jpg"
                prepend-icon="mdi-camera-plus"
                class="pa-0 ma-0 ml-2"
                style="flex: none!important;"
                @change="onSelectFile()"
              />
            </v-avatar>
          </div>

          <v-btn
            depressed
            block
            dark
            color="#00a884"
            class="mt-3"
            :loading="loading"
            :disabled="todaycount>5"
            @dblclick="dblclick"
            @click="whatsappsended"
          >
            {{ $t('tijiao') }}
          </v-btn>

          <v-divider class="opacity-3 my-3" />

          <v-subheader class="px-0 subtitle-2">
            {{ $t('jlck') }}
          </v-subheader>
          <v-carousel
            cycle
            height="400"
          >
            <v-carousel-item
              v-for="p in 4"
              :key="'i'+p"
              :src="require('@/assets/img/pic/upvimg-0'+p+'.jpg')"
            />
          </v-carousel>

          <v-divider class="opacity-3 my-3" />

          <v-card-title
            class="py-3 text-body-1"
          >
            {{ $t('lsjl') }}
          </v-card-title>

          <v-list
            color="box_bg"
            class="text-caption pt-0"
          >
            <div
              v-for="(item, index) in list"
              :key="'h'+index"
            >
              <v-divider class="opacity-3" />
              <v-list-item>
                <v-list-item-action>
                  {{ item.created }}
                </v-list-item-action>
                <v-list-item-content>
                  <div>
                    <img
                      width="50"
                      class="mx-1"
                      :src="item.imgs"
                    >
                  </div>
                </v-list-item-content>
                <v-list-item-action
                  v-if="item.status==0"
                  class="text-right"
                >
                  {{ $t('daishenhe') }}
                </v-list-item-action>
                <v-list-item-action
                  v-if="item.status==1"
                  class="text-right"
                >
                  <div>
                    <div class="orange--text font-weight-medium">
                      + R$ {{ item.money }}
                    </div>
                    <span class="green--text">{{ $t('yifafang') }}</span>
                  </div>
                </v-list-item-action>
                <v-list-item-action
                  v-if="item.status==-1"
                  class="text-right"
                >
                  <div class="text-center">
                    <div class="red--text">
                      {{ $t('weitongguo') }}
                    </div>
                  </div>
                </v-list-item-action>
              </v-list-item>
            </div>
          </v-list>
        </v-card-text>
      </v-card>
    </div>
    <template v-if="showdialog">
      <Login
        v-model="loginreg"
        @closeloginreg="closeloginreg"
        @showforget="showforget"
      />
    </template>
    <template v-if="forget">
      <Forget
        @closeforget="closeforget"
      />
    </template>
  </v-container>
</template>

<script>
import Login from '@/components/Login.vue'
import Forget from '@/components/Forget.vue'

export default {
  components: {
    Login,
    Forget
  },
  data: () => ({
    todaycount: 0,
    countdownhour: 0,
    awardsum: 0,
    process: 0,
    task: {},
    records: [],
    showdialog: false,
    loginreg: false,
    forget: false,
    loading: false,
    sending: false,
    hourtimer: null,
    daojishi: '',
    list: [],
    file1: null,
    f1: null,
    fburl: 'fb://',
    insurl: 'instagram://user?username=cbf_futebol',
    whatsappurl: 'whatsapp://send?'
  }),
  computed: {

  },
  watch: {

  },
  created() {
    if( (/iphone|ipod|ipad/.test( window.navigator.userAgent.toLowerCase() )) && (/safari/.test( window.navigator.userAgent.toLowerCase() ))==false ){
      this.fburl = "pgb://"+encodeURIComponent(this.fburl)
      this.insurl = "pgb://"+encodeURIComponent(this.insurl)
      this.whatsappurl = "pgb://"+encodeURIComponent(this.whatsappurl)
    }
  },
  mounted() {
    this.whatsapptask()
  },
  beforeDestroy() {

  },
  methods: {
    whatsapptask() {
      if(!this.getStorage('member_id')){
        return false
      }
      let paramObj = {
        member_id: this.getStorage('member_id'),
      }
      this.$server.whatsapptask(paramObj).then((response) => {
        if(response.code==200){
          this.todaycount = response.data.todaycount
          this.countdownhour = response.data.countdownhour
          this.awardsum = response.data.awardsum
          this.process = response.data.process
          this.task = response.data.task
          this.records = response.data.records
          this.hourcountdown()
        }
      })
    },
    whatsappsended(recordid) {
      this.sending = true
      let paramObj = {
        member_id: this.getStorage('member_id'),
        recordid: recordid
      }
      this.$server.whatsappsended(paramObj).then((response) => {
        setTimeout(() => {
          if(response.code==200){
            this.whatsapptask()
          }else{
            this.$snackbar.error(response.msg)
          }
          this.sending = false
        }, 10000)
      })
    },
    whatsappaward() {
      this.loading = true
      let paramObj = {
        member_id: this.getStorage('member_id'),
      }
      this.$server.whatsappaward(paramObj).then((response) => {
        if(response.code==200){
          this.whatsapptask()
        }else{
          this.$snackbar.error(response.msg)
        }
        this.loading = false
      })
    },
    hourcountdown() {
      this.hourtimer = setInterval(() => {
        if (this.countdownhour > 0) {
          this.countdownhour--
          let hours = Math.floor(this.countdownhour / 3600)
          let minutes = Math.floor(this.countdownhour / 60) % 60
          let seconds = this.countdownhour % 60
          this.daojishi = hours+':'+minutes+':'+seconds
        }else{
          this.daojishi = ''
          clearInterval(this.hourtimer)
          this.hourtimer = null
        }
      }, 1000)
    },
    showloginreg(tab) {
      this.loginreg = tab
      this.showdialog = true
    },
    closeloginreg() {
      this.loginreg = false
      this.showdialog = false
    },
    showforget() {
      this.forget = true
    },
    closeforget() {
      this.forget = false
    },
    dblclick() {
      console.log('dblclick')
    },
  }

}
</script>